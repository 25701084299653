import { useEffect } from "react";
import styles from "./LoaderJingle.module.css";
import React from "react";

const LoaderJingle = () => {

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setTimeout(() => {document.body.style.overflow = "auto"}, 5500);
  }, []);

  return (
    <div className={styles.container}>
      <svg
        className={styles.svg}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1500px"
        height="1500.000000pt"
        viewBox="0 0 1500.000000 1500.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M12011 8985 c-338 -166 -727 -520 -963 -877 l-70 -106 -237 -110
c-257 -120 -328 -142 -367 -117 -13 9 -24 23 -24 33 0 26 33 129 70 219 35 86
35 113 -3 113 -35 0 -77 -36 -257 -219 -162 -165 -287 -271 -319 -271 -25 0
-41 20 -41 51 0 15 20 90 45 167 48 148 53 182 31 182 -7 0 -62 -28 -122 -62
-60 -34 -133 -70 -163 -80 -71 -25 -171 -34 -200 -18 -21 11 -23 17 -16 58 3
26 9 63 12 83 12 85 -57 148 -160 149 -62 0 -109 -20 -138 -59 -40 -55 -154
-115 -348 -185 -100 -37 -190 -66 -200 -64 -21 5 -18 57 9 169 19 79 41 119
65 119 7 0 18 7 25 16 11 13 10 18 -7 36 -16 16 -28 19 -52 13 -34 -7 -51 -33
-81 -119 -16 -49 -19 -52 -101 -93 -249 -124 -364 -160 -473 -149 -91 9 -105
30 -61 94 29 43 31 55 10 76 -30 29 -80 -9 -126 -94 -29 -55 -102 -125 -288
-278 -114 -94 -181 -105 -181 -30 0 29 84 266 123 346 15 31 18 45 9 54 -13
13 -23 7 -184 -113 -144 -108 -369 -226 -411 -215 -32 8 -57 51 -57 100 0 19
16 85 35 147 20 63 35 125 33 139 -3 26 -9 27 -76 14 -22 -4 -47 0 -82 15 -60
26 -93 26 -149 1 -106 -48 -181 -127 -226 -238 l-23 -57 -134 -12 c-327 -28
-637 -132 -886 -296 -118 -77 -146 -100 -386 -306 -542 -464 -918 -689 -1324
-791 -164 -41 -273 -58 -453 -72 -337 -25 -724 35 -1045 163 -76 30 -114 28
-159 -5 -28 -21 -14 -31 101 -72 348 -125 708 -167 1113 -130 211 19 291 33
464 81 462 126 873 375 1482 898 83 71 182 152 220 181 241 181 535 281 891
302 111 7 114 6 114 -14 0 -26 36 -52 70 -52 41 0 160 63 225 120 33 28 76 64
96 80 l35 29 -4 -86 c-4 -70 -1 -93 13 -119 25 -46 82 -68 154 -59 65 8 237
93 360 178 45 31 81 54 81 51 0 -2 -22 -58 -50 -124 -58 -138 -62 -179 -24
-217 22 -22 32 -25 73 -21 82 8 166 56 301 172 173 149 149 138 268 125 165
-19 284 7 485 105 59 28 107 49 107 46 -1 -28 -42 -156 -55 -170 -9 -10 -64
-46 -123 -81 -126 -76 -242 -183 -273 -254 -39 -88 -2 -151 86 -149 107 2 220
119 340 352 29 57 59 100 78 114 26 19 378 160 384 155 1 -1 -5 -27 -13 -57
-42 -164 9 -270 130 -270 70 0 129 33 212 117 82 85 120 103 209 103 92 0 189
24 279 69 42 21 76 36 76 32 0 -3 -12 -40 -26 -81 -34 -100 -43 -200 -20 -234
21 -33 72 -42 119 -22 63 26 186 124 303 242 63 63 118 114 124 114 13 0 13
-18 -2 -95 -35 -195 109 -192 538 10 l111 52 -47 -93 c-77 -150 -140 -338
-140 -417 0 -53 28 -94 83 -122 65 -32 80 -32 190 6 175 58 280 123 668 411
404 300 399 296 399 315 0 36 -57 9 -203 -100 -654 -486 -636 -474 -796 -550
-153 -73 -221 -75 -257 -5 -14 26 -14 40 -4 91 30 155 182 489 249 550 20 18
93 62 161 99 418 220 811 519 961 731 26 38 53 85 60 104 10 31 9 39 -8 62
-17 22 -29 26 -74 29 -49 3 -66 -3 -168 -53z m167 3 c20 -20 14 -47 -25 -106
-85 -132 -353 -366 -613 -537 -246 -161 -380 -230 -380 -196 0 12 45 77 94
136 226 273 531 529 801 672 85 45 104 50 123 31z m-2889 -872 c31 -33 37 -87
16 -141 -12 -32 -24 -47 -41 -51 -57 -14 -134 50 -134 113 0 27 8 43 34 69 42
42 91 46 125 10z m-2644 -51 c43 -42 32 -65 -79 -165 -93 -85 -160 -130 -194
-130 -23 0 -24 3 -20 38 12 87 90 200 176 257 48 31 86 31 117 0z m2447 -111
c26 -25 71 -55 98 -68 l50 -23 -31 -46 c-34 -51 -107 -120 -153 -144 -15 -8
-39 -12 -52 -9 -28 7 -64 71 -64 113 0 34 22 120 40 153 13 26 50 70 58 70 3
0 27 -20 54 -46z m-738 -276 c-15 -51 -56 -120 -114 -192 -85 -108 -146 -141
-170 -95 -18 32 -4 67 55 140 56 69 128 128 186 154 46 19 51 19 43 -7z"
            className={styles.logo}
          />
          <path
            d="M4989 6672 c15 -29 15 -254 -1 -270 -9 -9 -5 -12 18 -12 l29 0 -3 69
-4 70 37 10 c52 14 75 38 75 77 0 43 -30 63 -105 71 -56 6 -57 5 -46 -15z m99
-18 c44 -31 16 -104 -40 -104 -15 0 -18 9 -18 60 0 66 13 76 58 44z"
            className={styles.wordOne + ' ' + styles.word}
          />
          <path
            d="M5535 6540 c0 -144 1 -150 20 -150 19 0 20 5 17 75 l-4 75 76 0 76 0
0 -63 c0 -36 -5 -68 -12 -75 -9 -9 -5 -12 18 -12 l29 0 0 150 0 150 -29 0
c-23 0 -27 -3 -18 -12 7 -7 12 -36 12 -65 l0 -53 -76 0 -76 0 4 65 c4 60 2 65
-17 65 -19 0 -20 -6 -20 -150z"
            className={styles.wordTwo + ' ' + styles.word}
          />
          <path
            d="M6211 6672 c-48 -24 -73 -62 -79 -120 -9 -96 50 -162 144 -162 90 0
154 63 154 150 0 114 -117 185 -219 132z m141 -29 c71 -64 60 -186 -21 -228
-41 -21 -69 -19 -107 7 -98 67 -78 226 31 250 28 6 74 -8 97 -29z"
            className={styles.wordThree + ' ' + styles.word}
          />
          <path
            d="M6797 6684 c-4 -4 -7 -17 -7 -28 1 -19 2 -19 11 -3 6 11 22 17 45 17
l35 0 -3 -140 c-3 -134 -2 -140 17 -140 19 0 20 6 17 140 l-3 140 35 0 c23 0
39 -6 46 -17 9 -16 10 -15 8 7 -3 25 -5 25 -98 28 -53 1 -99 0 -103 -4z"
            className={styles.wordFour + ' ' + styles.word}
          />
          <path
            d="M7431 6672 c-48 -24 -73 -62 -79 -120 -9 -96 50 -162 144 -162 90 0
154 63 154 150 0 114 -117 185 -219 132z m141 -29 c71 -64 60 -186 -21 -228
-41 -21 -69 -19 -107 7 -98 67 -78 226 31 250 28 6 74 -8 97 -29z"
            className={styles.wordFive + ' ' + styles.word}
          />
          <path
            d="M8110 6672 c-103 -51 -108 -201 -8 -262 39 -23 156 -29 163 -7 2 6 5
42 7 80 4 62 2 67 -17 67 -19 0 -20 -5 -17 -70 4 -67 3 -69 -22 -76 -50 -12
-115 24 -145 83 -24 46 -7 129 33 162 39 32 127 31 148 -2 5 -8 8 -4 8 9 0 12
-7 25 -16 28 -30 12 -99 6 -134 -12z"
            className={styles.wordSix + ' ' + styles.word}
          />
          <path
            d="M8668 6679 c17 -18 17 -260 0 -277 -9 -9 -5 -12 18 -12 l29 0 -3 76
c-3 50 0 75 7 73 6 -2 32 -35 58 -74 35 -50 55 -71 73 -73 l25 -4 -21 24 c-31
34 -84 115 -84 127 0 6 6 11 14 11 21 0 46 39 46 71 0 42 -30 61 -107 66 -56
5 -66 3 -55 -8z m110 -25 c43 -30 16 -94 -40 -94 -27 0 -28 1 -28 55 0 51 2
55 23 55 12 0 33 -7 45 -16z"
            className={styles.wordSeven + ' ' + styles.word}
          />
          <path
            d="M9346 6672 c-3 -5 -26 -64 -51 -133 -26 -68 -51 -129 -57 -136 -8
-10 -5 -13 11 -13 14 0 21 6 21 18 0 10 7 39 16 65 16 46 17 47 58 47 40 0 42
-1 59 -49 9 -27 17 -56 17 -65 0 -10 9 -16 25 -16 22 0 24 3 16 19 -6 10 -31
74 -56 142 -50 134 -50 135 -59 121z m17 -94 l16 -38 -34 0 c-23 0 -33 4 -30
13 28 80 26 79 48 25z"
            className={styles.wordEight + ' ' + styles.word}
          />
          <path
            d="M9839 6672 c15 -29 15 -254 -1 -270 -9 -9 -5 -12 18 -12 l29 0 -3 69
-4 70 37 10 c52 14 75 38 75 77 0 43 -30 63 -105 71 -56 6 -57 5 -46 -15z m99
-18 c44 -31 16 -104 -40 -104 -15 0 -18 9 -18 60 0 66 13 76 58 44z"
            className={styles.wordNine + ' ' + styles.word}
          />
          <path
            d="M10385 6540 c0 -144 1 -150 20 -150 19 0 20 5 17 75 l-4 75 76 0 76
0 0 -63 c0 -36 -5 -68 -12 -75 -9 -9 -5 -12 18 -12 l29 0 0 150 0 150 -29 0
c-23 0 -27 -3 -18 -12 7 -7 12 -36 12 -65 l0 -53 -76 0 -76 0 4 65 c4 60 2 65
-17 65 -19 0 -20 -6 -20 -150z"
            className={styles.wordTen + ' ' + styles.word}
          />
          <path
            d="M10973 6683 c3 -5 23 -39 47 -77 40 -67 41 -71 38 -142 l-3 -74 29 0
c23 0 27 3 18 12 -7 7 -12 38 -12 71 0 50 5 69 37 121 20 33 43 69 51 79 14
16 13 17 -11 17 -17 0 -25 -5 -22 -12 3 -7 -10 -39 -28 -71 l-33 -57 -32 56
c-18 31 -32 62 -32 70 0 8 -10 14 -26 14 -14 0 -23 -3 -21 -7z"
            className={styles.wordEleven + ' ' + styles.word}
          />
        </g>
      </svg>
    </div>
  );
};

export default LoaderJingle;
